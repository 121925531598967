<template>
    <div class="modal fade dialogbox" id="postContactMSG" tabindex="-1"
         role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-icon text-success">
                    <ion-icon name="people-outline"></ion-icon>
                </div>
                <div class="modal-header">
                    <h5 class="modal-title">We will be in touch 😊</h5>
                </div>
                <div class="modal-body">
                    <p>
                        Your message have been received. <br>
                        Our contact personnel will contact you within the next hour
                    </p>
                    <p class="alert alert-outline-primary mt-2">
                        Do you know you can refer as much friends on RelaiEarn and earn a certain percentage
                        every time they purchase a crypto? <br>
                        <router-link data-bs-dismiss="modal" :to="{name: 'Register'}">Click here to begin</router-link>
                    </p>
                </div>
                <div class="modal-footer">
                    <div class="btn-inline">
                        <a href="javascript:void(0)" class="btn" data-bs-dismiss="modal">Dismiss</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'postContactMessage',
    mounted() {
        const _modal = $('#postContactMSG');
        _modal.on('hide.bs.modal', ()=>{
            this.$router.push({name: 'Register'})
        })
    }
};
</script>

<style scoped>

</style>