<template>
    <div>
        <!-- App Header -->
        <div class="appHeader no-border transparent position-absolute">
            <div class="left">
                <router-link :to="{name: 'Register'}" class="headerButton goBack">
                    Register
                </router-link>
            </div>
            <div class="pageTitle">
                <router-link :to="{name: 'MetricsWallet'}" class="headerButton">
                    <img src="../assets/img/metriclogo_color.png" alt="logo" class="logo">
                </router-link>
            </div>
            <div class="right">
                <router-link :to="{name: 'Auth'}" class="headerButton goBack">
                    Login
                </router-link>
            </div>
        </div>
        <!-- * App Header -->

        <!-- App Capsule -->
        <div id="appCapsule">
            <div class="section mt-2">
                <div class="card">
                    <div class="card-body">
                        <div class="p-1">
                            <div class="text-center">
                                <h2 class="text-primary">Get in Touch</h2>
                                <p>Fill the form to contact us</p>
                            </div>
                            <form>
                                <div class="form-group basic animated">
                                    <div class="input-wrapper">
                                        <label class="label" for="name2">Your name</label>
                                        <input type="text" v-model="contact.name" class="form-control" id="name2" placeholder="Your name">
                                    </div>
                                </div>

                                <div class="form-group basic animated">
                                    <div class="input-wrapper">
                                        <label class="label" for="email2">E-mail</label>
                                        <input type="email" v-model="contact.email" class="form-control" id="email2" placeholder="E-mail">
                                    </div>
                                </div>

                                <div class="form-group basic animated">
                                    <div class="input-wrapper">
                                        <label class="label" for="textarea2">Message</label>
                                        <textarea id="textarea2" v-model="contact.message" rows="4" class="form-control" placeholder="How can we help...">

                                        </textarea>
                                    </div>
                                </div>

                                <div class="mt-2">
                                    <button @click.prevent="proceed" type="submit" class="btn btn-primary btn-lg btn-block">Send</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section mt-2">
                <div class="card">
                    <div class="card-body">
                        <div class="p-1">
                            <div class="text-center">
                                <h2 class="text-primary">Our Address</h2>
                                <p class="card-text">
                                    <strong>Office 1:</strong> Langstrasse 136, 8004 Zürich, Switzerland <br>
                                </p>
                                <p class="card-text">
                                    <strong>Office 2:</strong> 20-22 Wenlock Road, London, England, N1 7GU
                                </p>
                                <p class="card-text">
                                    <strong>Office 3:</strong> Tokyo, Tokyo Japan
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <post-contact-message/>
    </div>
</template>

<script>
import Contact from '../models/contact';
import methodsMixin from "../utils/methodsMixin";
import postContactMessage from '../components/modals/postContactMessage';

export default {
    name: 'Contact',
    data(){
        return {
            contact: new Contact().data
        }
    },
    mixins: [methodsMixin],
    methods: {
        async proceed(){
            if(!this.contact.name || !this.contact.email || !this.contact.message){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'You are required to complete all fields',
                    timeout: 1500
                });
            }
            if(!this.validateEmail(this.contact.email)){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Enter a valid email address',
                    timeout: 1500
                });
            }
            this.$loader.show();
            const response = await this.$store.dispatch('contact/addContact', this.contact);
            this.$loader.hide();
            if(response.status){
                $('#postContactMSG').modal('show');
                // this.contact = new Contact().data;
            }else{
                return this.$store.dispatch('notification/display_noti', {
                    message: response.message,
                    timeout: 1500
                });
            }
        }
    },
    components: {
        postContactMessage
    },
};
</script>

<style scoped>

</style>